<template>
	<page>
		<module title="人员变更详情">
			<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
			<c-detail>
				<c-detail-item label="项目名称" full>
					{{detailInfo.project_name}}
				</c-detail-item>
				<c-detail-item label="业主单位">
					{{detailInfo.yzdw_name}}
				</c-detail-item>
				<c-detail-item label="监理单位">
					{{detailInfo.jldw_name}}
				</c-detail-item>
				<c-detail-item label="项目经理">
					<span :class="{'s-red': detailInfo.xmjl_xm != detailInfo.old_xmjl_xm}">{{detailInfo.xmjl_xm}}</span>
					<span v-if="detailInfo.xmjl_lxdh" :class="{'s-red': detailInfo.xmjl_lxdh != detailInfo.old_xmjl_lxdh}">（{{detailInfo.xmjl_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="(原)项目经理">
					{{detailInfo.old_xmjl_xm}}<span v-if="detailInfo.old_xmjl_lxdh">（{{detailInfo.old_xmjl_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="技术总工">
					<span :class="{'s-red': detailInfo.jszg_xm != detailInfo.old_jszg_xm}">{{detailInfo.jszg_xm}}</span>
					<span v-if="detailInfo.jszg_lxdh" :class="{'s-red': detailInfo.jszg_lxdh != detailInfo.old_jszg_lxdh}">（{{detailInfo.jszg_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="(原)技术总工">
					{{detailInfo.old_jszg_xm}}<span v-if="detailInfo.old_jszg_lxdh">（{{detailInfo.old_jszg_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="安全员">
					<span :class="{'s-red': detailInfo.aqy_xm != detailInfo.old_aqy_xm}">{{detailInfo.aqy_xm}}</span>
					<span v-if="detailInfo.aqy_lxdh" :class="{'s-red': detailInfo.aqy_lxdh != detailInfo.old_aqy_lxdh}">（{{detailInfo.aqy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="(原)安全员">
					{{detailInfo.old_aqy_xm}}<span v-if="detailInfo.old_aqy_lxdh">（{{detailInfo.old_aqy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="施工员">
					<span :class="{'s-red': detailInfo.sgy_xm != detailInfo.old_sgy_xm}">{{detailInfo.sgy_xm}}</span>
					<span v-if="detailInfo.sgy_lxdh" :class="{'s-red': detailInfo.sgy_lxdh != detailInfo.old_sgy_lxdh}">（{{detailInfo.sgy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="(原)施工员">
					{{detailInfo.old_sgy_xm}}<span v-if="detailInfo.old_sgy_lxdh">（{{detailInfo.old_sgy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="质检员">
					<span :class="{'s-red': detailInfo.zjy_xm != detailInfo.old_zjy_xm}">{{detailInfo.zjy_xm}}</span>
					<span v-if="detailInfo.zjy_lxdh" :class="{'s-red': detailInfo.zjy_lxdh != detailInfo.old_zjy_lxdh}">（{{detailInfo.zjy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="(原)质检员">
					{{detailInfo.old_zjy_xm}}<span v-if="detailInfo.old_zjy_lxdh">（{{detailInfo.old_zjy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="企业名称">
					{{detailInfo.zbqy_name}}
				</c-detail-item>
				<c-detail-item label="申请时间">
					{{detailInfo.create_date}}
				</c-detail-item>
				<c-detail-item label="变更说明">
					{{detailInfo.remark}}
				</c-detail-item>
				<c-detail-item label="其它材料">
					<c-file :src="detailInfo.file_path"></c-file>
				</c-detail-item>
			</c-detail>
			
			<c-detail title="审批流程">
				<c-detail-item label="审批记录" full>
					<timeline :data="timeline"></timeline>
				</c-detail-item>
			</c-detail>
			
			<div v-if="approveButton" slot="button" class="flex-center">
				<c-button color="green" size="m" @click="$refs.approve.resolve()">审批通过</c-button>
				<c-button color="red" size="m" @click="$refs.approve.reject()">驳回</c-button>
			</div>
		</module>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
	</page>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import approve from '@/components/module/approve.vue'
	
	export default {
		components: {
			flowNode,
			timeline,
			approve
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '已提交申请'
				}, {
					name: '（监理方）审批'
				}, {
					name: '（业主单位）审批'
				}, {
					name: '办理完结'
				}],
				detailInfo: {},
				timeline: []
			}
		},
		
		computed: {
			currentNode() {
				return this.detailInfo.state <= 3 ? this.detailInfo.state + 1 : 0;
			},
			
			rejectNode() {
				return this.detailInfo.state == 4 ? (this.detailInfo.reject_user_type == 8 ? 2 : 3) : 0;
			},
			
			approveButton() {
				return this.detailInfo.state == 2;
			}
		},
		
		watch: {
			id() {
				this.updateDetail();
			}
		},
		
		mounted() {
			this.updateDetail();
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/ry/detail',
					data: {
						ry_id: this.id
					},
					success: data => {
						this.detailInfo = data.ryInfo;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			approveHandle(state, remark, stop, next) {
				stop();
				this.request({
					url: '/project/ry/updateStateYz',
					data: {
						ry_id: this.id,
						state: state ? 3 : 4,
						yz_remark: remark
					},
					loading: true,
					success: data => {
						next();
						this.$message({
							text: '审批成功',
							resolve: () => {
								this.updateDetail();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.s-red {
		color: #f00;
	}
</style>