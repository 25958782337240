import { render, staticRenderFns } from "./person_change_detail.vue?vue&type=template&id=236ceed3&scoped=true"
import script from "./person_change_detail.vue?vue&type=script&lang=js"
export * from "./person_change_detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236ceed3",
  null
  
)

export default component.exports